// ====================================
// メインエリアのレイアウトに関わる設定
// ====================================

.l-main {
    &__inner{
        padding-top: 5.2rem;
        background-image: url(../img/top/bg-sp.png);
        background-repeat:no-repeat;
        background-position: 50% 50%;
        background-attachment:fixed;
        background-size: cover;
        @include mq(md){
          padding-top: 7.7rem;
          background-image: url(../img/top/bg-pc.png);
          background-size: auto;
        }
      }

}

.l-section {


}

// ====================================
// kv部分
// ====================================
.kv{
    position: relative;
    background-color: #000;

    &__ttl{
        color: #fff;
        position: absolute;
        // -ms-writing-mode: tb-rl;
        // writing-mode: vertical-rl;
        font-size: 2rem;
        font-weight: bold;
        top: calc( 100vh / 2 - 5rem);
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #0000004a;
        padding: 2rem 0;
        z-index: 66;
        @include mq(tb){
            top: calc( 100vh / 2 - 5rem);
            left: 50%;
            font-size: 3rem;
            transform: translate(-50%,-50%);
        }
        .kv-flex{
          display: flex;
          width: 105px;
          @include mq(tb){
            width: 122px;
          }
        }
        & h2{
          -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
        }
        & p{
            padding-top: 2rem;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
        }
        & span{
            font-size: 4rem;
            font-weight: bold;
            @include mq(tb){
                font-size: 4rem;
            }
        }
        &::before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 50px 105px;
            border-color: transparent transparent #0000004a transparent;
            position: absolute;
            top: -50px;
            @include mq(tb){
                border-width: 0 0 60px 122px;
                top: -60px;
            }
        }
        &::after{
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 50px 105px 0 0;
            border-color: #0000004a transparent transparent transparent;
            position: absolute;
            bottom: -50px;
            left: 0;
            @include mq(tb){
                border-width: 60px 122px 0 0;
                bottom: -60px;
            }
        }


    }
  }

  .kvSlide{
    height: 100vh;
    @include mq(tb){
        min-height: 70rem;
    }
    &__item{
        width: 100%;
        height: 100%;
    }
    & figure{
        width: 100%;
        height: 100%;
    }
    & img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: right;
        @include mq(tb){
            object-position: center;
        }

    }
  }

  .logo-bg{
    display: block;
    width: 80px;
    opacity: 0.5;
    margin: 0 auto;
    padding-bottom: 5px;
  }
// ====================================
// news部分
// ====================================
.news-pad{
    padding: 9rem 0;
    background-color: #fff;
    @include mq(md){
       padding: 9rem 0 14rem 0;
    }
}
.news{
    width: 100%;
    height: auto;
    background-image: url(../img/top/news-sp.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 6rem 0;
    &-ttl{
        padding-bottom: 6rem !important;
        &::before{
            content: "News" !important;
        }
    }
    &-box{
        width: 90%;
        max-width: 35rem;
        margin: 0 auto;
        background: $cGray;
        padding: 0 1rem;
        @include mq(md){
            width: 70%;
            max-width: 60rem;
        }
    }
    &-list{
        padding: 0.8rem 0;
        font-size: 1.4rem;
        @include mq(md){
            display: flex;
        }
        &:not(:last-child){
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        & dd{
            padding-right: 2.5rem;
            letter-spacing: normal;
            font-weight: bold;
            @include mq(md){
                padding-left: 3rem;
                padding-right: 10rem;
            }
        }
        & dt{
            padding-left: 3rem;
            & a{
            color: $cYellow;
            font-weight: bold;
            font-size: 1.4rem;
            }
        }
        &:hover{
            opacity: 0.7;
        }
    }
}

// ====================================
// about部分
// ====================================
.about{
    width: 100%;
    height: auto;
    &-top{
        width: 100%;
        height: 20rem;
        background-color: #fff;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        position: relative;
        & span{
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            font-size: 2rem;
            font-weight: bold;
            z-index: 7;
            @include mq(md){
                font-size: 3rem;
            }
        }
        &::before{
            content:'';
            display: block;
            width: 5rem;
            height: 5rem;
            // background-color: #fff;
            position: absolute;
            left: 50%;
            top: 20%;
            transform: translateX(-50%);
            background-image: url(../img/top/logo.png);
            background-size: contain;
            background-repeat: no-repeat;
            @include mq(md){
                width: 7rem;
                height: 7rem;
                top: -11%;
            }
        }
    }
    &-bottom{
        position: relative;
        height: auto;
        background-color: black;
        background-image: url(../img/top/about-bottom-bg.jpg);
        background-size: contain;
        background-repeat: no-repeat;
        padding-bottom: 6rem;
        @include mq(md){
            background-size: cover;
            background-position: right;
        }
        & span{
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            color: #fff;
            font-size: 2rem;
            display: block;
            font-weight: bold;
            @include mq(md){
                font-size: 3rem;
            }
        }
        & dd{
            color: $cYellow;
            text-align: center;
            padding-top: 16rem;
        }
        & dt{
            color: #fff;
            text-align: center;
            padding: 6rem 0;
            text-align: left;
            line-height: 3;
            width: 90%;
            margin: 0 auto;
            @include mq(md){
                text-align: center;
                line-height: 3.5;
            }
        }
        & p{
            text-align: center;
            color: #fff;
        }
    }
    &__btn{
        height: auto;
        width: 22rem;
        margin: 0 auto;
        background-color: rgba(201, 181, 0,1);
        line-height: 3rem;
        font-weight: bold;
        transition: all 0.2s !important;
        &__box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 2rem;
            & img{
                width: auto;
                height: 0.8rem;
            }
        }
        & a p{
            color: #fff;
            width: 100%;
            display: block;
            font-weight: bold;
        }
        &:hover{
            background-color: rgba(201, 181, 0,0.7);
        }
    }
}





// ====================================
// access部分
// ====================================
.frontaccess{
    height: auto;
    padding: 15rem 0;

    &__ttl{
        &::before{
            content: "Access" !important;
        }
    }

    &__txt{
        padding-top: 6rem;
        line-height: 2.2;
        @include mq(md){
            padding-top: 10rem;
            font-size: 1.8rem;
        }
        & span{
            font-weight: bold;
            cursor: pointer;
        }
    }
}

// ====================================
// gallery部分
// ====================================
.gallery__ttl{
    &::before{
        content: "Gallery" !important;
    }
}
.img-wrap {
    width: 100%;
    margin: 0 auto;
    height: auto;
    margin-bottom: 18rem;
    @include mq(md){
        margin-bottom: 22rem;
    }
    & .img{
        width:100%;
        height: auto;
        background:#E6E6E6;
        margin-bottom: 1rem;
        @include mq(md){
        display: flex !important;
        justify-content: space-between;
        margin-bottom: 0;
        align-items: stretch;
        height: 600px;
        }
    }
    & .large{
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: 1rem;
        @include mq(md){
            width: calc(( 100% - 3rem) / 2);
            margin-bottom: 0;
            height: 100%;
        }
    }
    & .img-side{
        // width: calc(( 100% - 1rem) / 2);
        width: 100%;
        height: auto;
        @include mq(md){
            width: calc(( 100% - 3rem) / 2);
        }
    }

    & .mid{
        width: 100%;
        height: calc(( 100% - 1rem) / 2);
        margin-bottom: 1rem;
        @include mq(md){
            height: calc(( 100% - 3rem) / 2);
            margin-bottom: 3rem;
        }
    }
    & .img-small{
        width: 100%;
        height: calc(( 100% - 1rem) / 2);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        @include mq(md){
            height: calc(( 100% - 3rem) / 2);
        }
    }
    & .small{
        width: calc(( 100% - 1rem) / 2);
        height: 100%;
        object-fit: cover;
        @include mq(md){
            width: calc(( 100% - 3rem) / 2);
        }
    }
}





// Aboutページ
.about{
    &__ttl{
        padding-bottom: 0 !important;
        width: 2.8rem !important;

        &::before{
            content: "About Ryushin Yamada" !important;
            left: 2.5rem !important;
        }
    }
    &txt__wrapper{
        padding: 2rem 0;
        background-color: #fff;
        @include mq(md){
            position: relative;
            padding: 4rem 0;
        }
    }
    &txt__ttl{
        @include mq(md){
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            position: absolute;
            right: 10%;
        }
        & p{
            font-size: 1.8rem;
            font-weight: bold;

        }
        &01{
            font-size: 1.2rem !important;
            @include mq(md){
                padding-left: 1rem;
            }
        }
        &02{
            padding-top: 1rem;
        }
        & span{
            font-size: 4rem;
            color: $cYellow;
            line-height: 1;
            font-weight: bold;
            padding-left: 2rem;
            padding-top: 1rem;
            @include mq(md){
                font-size: 6rem;
            }
        }
    }
    &txt p{
        padding-top: 2.5rem;
        line-height: ( 60 / 25);
        font-size: 1.4rem;
        font-weight: bold;
        @include mq(md){
         font-size: 1.6rem;
         padding-top: 0;
         line-height: 4;
        }
    }

}

.history{
    &__wrapper{
        background-image: url(../img/top/bg-sp.png);
        padding: 2rem 0;
        @include mq(md){
            padding: 2rem 0 4rem 0;
         }
        & ul{
            padding-top: 2rem;
            line-height: ( 60 / 25);
            @include mq(md){
               padding-left: 5rem;
               line-height: 3;
            }
        }
    }
}

.aboutpage-ttl{
    font-size: 3rem;
    font-weight: bold;
    border-bottom: 1px solid $cPurple;
}

.book{
    &__wrapper{
        padding: 2rem 0 10rem 0;
        background-color: #fff;
        @include mq(md){
            padding: 2rem 0 16rem 0;
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include mq(md){
            justify-content: space-around;
        }
    }
    &__item{
        width: calc(( 100% - 4rem) / 2);
        margin-top: 2rem;
        @include mq(md){
            width: calc(( 100% - 5.6rem) / 3);
            padding: 0 4rem;
            margin-top: 4rem;
        }
        &:hover{
            opacity: 0.7;
        }
    }
}

// 交通案内ページ
.access__box{
    padding-top: 3rem;
    @include mq(md){
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;
    }
    & dl{
        padding: 1rem 2rem 2rem 2rem;
        border: 1px solid $cGold;
        background-color: #fff;

        @include mq(md){
            width: 48%;
        }
        &:first-child{
            margin-bottom: 1rem;
            @include mq(md){
                margin-bottom: 0;
            }
        }

    }
    & dd{
        font-size: 2rem;
        color: $cGold;
        font-weight: bold;
        &::before{
            content: '';
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: $cGold;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    }
    & dt{
        font-size: 1.2rem;
        letter-spacing: normal;
        font-weight: bold;
        padding-left: 2rem;
        padding-top: 1rem;
    }
}
.access__txt{
    padding-top: 1rem;
    @include mq(md){
        padding-top: 3rem;
    }
    & p{
        text-align: center;
        padding-bottom: 0 !important;
    }
    & span{
        font-weight: bold;
    }
}

// お知らせ個別ページ
.single-txt{
  width: 80%;
  max-width: 500px;
  & p{
    text-align: left !important;
  }
}
