// ====================================
// ハンバーガーメニューに関わる設定
// ====================================
.navBar{
  width: 3rem;
  height: 1.8rem;
  cursor: pointer;
  position: relative;

  &__line{
    display: block;
    width: 100%;
    height: 0.1rem;
    background: #000;
    position: absolute;

    &.line01{

      &.active {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &.line02{
      top: 50%;
      transform: translateY(-50%);
      &.active{
        display: none;
      }
    }
    &.line03{
      bottom: 0;
      &.active {
        top: 50%;
        transform: translateY(-50%) rotate(-315deg);
      }
    }
  }
}

