// ====================================
// ヘッダーのレイアウトに関わる設定
// ====================================

// .l-header {

// }

// .header{

// }
.l-header {
    position: fixed;
    width: 100vw;
    height: 5.2rem;
    background: #fff;
    z-index: 1000;

    @include mq(md){
      height: 7.7rem;
      padding: 0 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @include mq(lg){
      padding: 0 6rem 0 7.3rem;
    }
  }

  .header{

    &__head{
      // background: gray;
      height: 100%;
      display: flex;
    //   justify-content: center;
      align-items: center;
      z-index: 5;

      @include mq(md){
        justify-content: space-between;
      }
    }

    &__logo{
      width: 10rem;
      padding-left: calc(4vw - 1rem);
      @include mq(md){
          width: 16rem;
        padding-left: 0;
      }
    }

    &__btn{
      width: 4rem;
      height: 4rem;
      position: fixed;
      top: 2.5rem;
      right: calc(4vw - 1rem);
      transform: translateY(-50%);
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq(md){
        display: none;
      }
    }
    &__{}

  }

  .gnav{
    background: #fff;
    position: fixed;
    top: 5.2rem;
    left: 0;
    padding: 0 $sp-side-margin;
    width: 100vw;
    height: calc(100vh - 5.2rem);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: $transition-normal;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    @include mq(md){
      opacity: 1;
      visibility: visible;
      position: static;
      width: auto;
      height: auto;
      background: transparent;
      padding: 0;
    }

    &__list{

      @include mq(md){
        display: flex;
        white-space: nowrap;
      }
    }
    &__item{

      &:not(:last-of-type){
        border-bottom: 1px solid #7D7A76;

        @include mq(md){
          border: none;
          margin-right: 4rem;
        }
        @include mq(lg){
          margin-right: 4rem;
        }
      }
      &:hover{
          opacity: 0.5;
      }
    }
    &__link{
      font-weight: 500;
      color: #000;
      display: block;
      text-align: center;
      padding: 2rem 0;
        font-weight: bold;
      @include mq(md){
        padding: 0;
        text-align: left;
        // color: #000;
      }

      &-menu{
        color: #fff;
        font-size: calcvw(54);
        // font-family: $font-en;
        font-weight: 700;

        @include mq(tb){
          font-size: 3.6rem;
        }
      }
    }
  }
