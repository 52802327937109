// ====================================
// display
// ====================================
.d-none{
  display: none;
}
.d-block{
  display: block;
}
.d-inline-block{
  display: inline-block;
}
.d-flex{
  display: flex;
}

// ====================================
// テキスト
// ====================================
$alignList: left, center, right, justify;

@each $align in $alignList {
  .text-#{$align}{
    text-align: #{$align} !important;
  }
}

@include mq(md){

  @each $align in $alignList {
    .text-md-#{$align}{
      text-align: #{$align} !important;
    }
  }

}


// ====================================
// フォント
// ====================================

.text-100{font-weight: 100;}
.text-400{font-weight: 400;}
.text-500{font-weight: 500;}
.text-600{font-weight: 600;}
.text-700{font-weight: 700;}
.text-900{font-weight: 900;}

// ====================================
// 余白とサイズの調整
// ====================================

// width
.w100{
  width: 100%;
}

@for $value from 0 through 6 {
  .m{
    &-#{$value}{margin: #{$value}rem !important;}
    &b-#{$value}{margin-bottom: #{$value}rem !important;}
    &t-#{$value}{margin-top: #{$value}rem !important;}
    &l-#{$value}{margin-left: #{$value}rem !important;}
    &r-#{$value}{margin-right: #{$value}rem !important;}
  }
  .p{
    &-#{$value}{padding: #{$value}rem !important;}
    &b-#{$value}{padding-bottom: #{$value}rem !important;}
    &t-#{$value}{padding-top: #{$value}rem !important;}
    &l-#{$value}{padding-left: #{$value}rem !important;}
    &r-#{$value}{padding-right: #{$value}rem !important;}
  }
}

@include mq(md) {
  @for $value from 0 through 5 {
    .m{
      &-md-#{$value}{margin: #{$value}rem !important;}
      &b-md-#{$value}{margin-bottom: #{$value}rem !important;}
      &t-md-#{$value}{margin-top: #{$value}rem !important;}
      &l-md-#{$value}{margin-left: #{$value}rem !important;}
      &r-md-#{$value}{margin-right: #{$value}rem !important;}
    }
    .p{
      &-md-#{$value}{padding: #{$value}rem !important;}
      &b-md-#{$value}{padding-bottom: #{$value}rem !important;}
      &t-md-#{$value}{padding-top: #{$value}rem !important;}
      &l-md-#{$value}{padding-left: #{$value}rem !important;}
      &r-md-#{$value}{padding-right: #{$value}rem !important;}
    }
  }
}

// margin
.m{
  &x-auto{
    margin-right: auto;
    margin-left: auto;
  }
}

// ====================================
// その他
// ====================================
.lh-1{
  line-height: 1 !important;
}
.lh-15{
  line-height: 1.5 !important;
}
.lh-2{
  line-height: 2 !important;
}
