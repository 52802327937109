// ====================================
// Form & Button リセット
// ====================================

// Form Reset
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  transition: all 150ms ease-in-out;
  background: #000;
  border: none;
  color: #fff;
  outline: none;
  padding: 1rem;
  text-decoration: none;
  vertical-align: bottom;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background: #003A6B;
  cursor: pointer;
}

button:visited,
input[type="button"]:visited,
input[type="reset"]:visited,
input[type="submit"]:visited {
  color: #fff;
  text-decoration: none;
}


input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  font: inherit;
  // -webkit-backface-visibility: hidden;
  background: none;
  border: solid 1px #5F5E5E;
  outline: none;
  padding: 1rem;
  -webkit-appearance: none;
  outline-offset: 0;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  font-size: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  border-color: #003A6B;
  // outline: thin solid rgba(0, 115, 170, 0.15);
  // outline-offset: -4px;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

// ====================================
// iOSでのデフォルトスタイルをリセット
// ====================================

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}
