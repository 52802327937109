// ====================================
// Project個別設定
// ====================================

.vsp-full{

}

// ====================================
// 見出し設定
// ====================================
.ttl {

}

// ====================================
// テキスト設定
// ====================================
.txt{

}

.color{

}

// ====================================
// Listに関する設定
// ====================================
.list{

}


// ====================================
// Figure設定
// ====================================
.fig{

}
// ====================================
// 背景設定
// ====================================
.bg {


}


// ====================================
// Border設定
// ====================================
.border-white {
  border-color: #fff !important;
}

// ====================================
// Link設定
// ====================================
.link {

}
// ====================================
// BOX設定
// ====================================
.box01{

}
.box02{

}



// ====================================
// PageTopボタン設定
// ====================================
.pageTop {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  display: none;

  &.active{
    display: block;
  }
}

