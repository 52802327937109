// ====================================
// Break Points
// ====================================

// breakpointの設定
$mq-breakpoints: (xl: 90em, // 1440px
  lg: 80em, // 1280px
  md: 64em, // 1024px
  tb: 48em, // 768px
  sm: 36em, // 576px
  xs: 25.875em // 414px
) !default;

// Media Queryの設定
@mixin mq($breakpoint: md, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);

  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 0.0625;
  }

  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// ====================================
// VPC VSP
// ====================================
.hsp{
  @include mq(md) {
    display: none;
  }
}
.hpc{
  display: none;
  @include mq(md) {
    display: block;
  }
}
.vsp{
  @include mq(md) {
    display: none;
  }
}
.vpc{
  display: none;
  @include mq(md) {
    display: block;
  }
}

// ====================================
// メディアクエリ max min フレキシブル対応用
// ====================================

@mixin max-w($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin min-w($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}


// ====================================
// Font関係のスタイルシート
// ====================================
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700;900&display=swap');

// Font Family
// $font-primary: 'Noto Serif JP', "ヒラギノ角ゴ Pro W3", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, 游ゴシック体, メイリオ, sans-serif;
$font-primary: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
//font-weight
$font-weight-black: 900;
$font-weight-bold: 700;
$font-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-nomal: 400;

$font-style-italic: italic;

// color
$color-base: #000;
$color-1st: #000000;
$color-2nd: #6c848d;
$color-3rd: #6B6F59;

$cGray: #E6E6E6;
$cYellow: rgb(201, 181, 0);
$cPurple: #271353;
$cGold: #C69C6D;


// ====================================
// VW 計算
// ====================================

$sp-design-width: 750; // SPデザインの幅を設定

@function calcvw($num, $width: $sp-design-width) {
  @return (1vw * $num / $width * 100);
}

// ====================================
// SP サイド余白
// ====================================

$sp-side-margin: calcvw(40);


// ====================================
// Mixin
// ====================================

$transition-normal: all .4s ease-in;

