@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: bottom;
  background: transparent;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-table;
  min-height: 1%;
}

/* Hides from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* End hide from IE-mac */
.clear {
  clear: both;
}

@media screen and (min-width: 64em) {
  .hsp {
    display: none;
  }
}

.hpc {
  display: none;
}

@media screen and (min-width: 64em) {
  .hpc {
    display: block;
  }
}

@media screen and (min-width: 64em) {
  .vsp {
    display: none;
  }
}

.vpc {
  display: none;
}

@media screen and (min-width: 64em) {
  .vpc {
    display: block;
  }
}

a[href^="tel:"] {
  cursor: default;
}

@media screen and (min-width: 48em) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  transition: all 150ms ease-in-out;
  background: #000;
  border: none;
  color: #fff;
  outline: none;
  padding: 1rem;
  text-decoration: none;
  vertical-align: bottom;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background: #003A6B;
  cursor: pointer;
}

button:visited,
input[type="button"]:visited,
input[type="reset"]:visited,
input[type="submit"]:visited {
  color: #fff;
  text-decoration: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  font: inherit;
  background: none;
  border: solid 1px #5F5E5E;
  outline: none;
  padding: 1rem;
  -webkit-appearance: none;
  outline-offset: 0;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  font-size: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  border-color: #003A6B;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.75;
  letter-spacing: 0.2em;
  font-size: 1.3rem;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'palt';
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  color: #000;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

p {
  text-align: justify;
}

.fwb {
  font-weight: bold;
}

.tac {
  text-align: center;
}

body {
  font-size: 1.2rem;
}

@media screen and (min-width: 64em) {
  body {
    font-size: 1.3rem;
  }
}

a {
  transition: all .4s ease-in-out;
  text-decoration: none;
}

a:hover {
  opacity: 0.9;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

@media screen and (min-width: 64em) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
}

.text-100 {
  font-weight: 100;
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-900 {
  font-weight: 900;
}

.w100 {
  width: 100%;
}

.m-0 {
  margin: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.p-0 {
  padding: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.m-4 {
  margin: 4rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.ml-4 {
  margin-left: 4rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.m-5 {
  margin: 5rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.ml-5 {
  margin-left: 5rem !important;
}

.mr-5 {
  margin-right: 5rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pr-5 {
  padding-right: 5rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.ml-6 {
  margin-left: 6rem !important;
}

.mr-6 {
  margin-right: 6rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pl-6 {
  padding-left: 6rem !important;
}

.pr-6 {
  padding-right: 6rem !important;
}

@media screen and (min-width: 64em) {
  .m-md-0 {
    margin: 0rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .mt-md-0 {
    margin-top: 0rem !important;
  }
  .ml-md-0 {
    margin-left: 0rem !important;
  }
  .mr-md-0 {
    margin-right: 0rem !important;
  }
  .p-md-0 {
    padding: 0rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0rem !important;
  }
  .pt-md-0 {
    padding-top: 0rem !important;
  }
  .pl-md-0 {
    padding-left: 0rem !important;
  }
  .pr-md-0 {
    padding-right: 0rem !important;
  }
  .m-md-1 {
    margin: 1rem !important;
  }
  .mb-md-1 {
    margin-bottom: 1rem !important;
  }
  .mt-md-1 {
    margin-top: 1rem !important;
  }
  .ml-md-1 {
    margin-left: 1rem !important;
  }
  .mr-md-1 {
    margin-right: 1rem !important;
  }
  .p-md-1 {
    padding: 1rem !important;
  }
  .pb-md-1 {
    padding-bottom: 1rem !important;
  }
  .pt-md-1 {
    padding-top: 1rem !important;
  }
  .pl-md-1 {
    padding-left: 1rem !important;
  }
  .pr-md-1 {
    padding-right: 1rem !important;
  }
  .m-md-2 {
    margin: 2rem !important;
  }
  .mb-md-2 {
    margin-bottom: 2rem !important;
  }
  .mt-md-2 {
    margin-top: 2rem !important;
  }
  .ml-md-2 {
    margin-left: 2rem !important;
  }
  .mr-md-2 {
    margin-right: 2rem !important;
  }
  .p-md-2 {
    padding: 2rem !important;
  }
  .pb-md-2 {
    padding-bottom: 2rem !important;
  }
  .pt-md-2 {
    padding-top: 2rem !important;
  }
  .pl-md-2 {
    padding-left: 2rem !important;
  }
  .pr-md-2 {
    padding-right: 2rem !important;
  }
  .m-md-3 {
    margin: 3rem !important;
  }
  .mb-md-3 {
    margin-bottom: 3rem !important;
  }
  .mt-md-3 {
    margin-top: 3rem !important;
  }
  .ml-md-3 {
    margin-left: 3rem !important;
  }
  .mr-md-3 {
    margin-right: 3rem !important;
  }
  .p-md-3 {
    padding: 3rem !important;
  }
  .pb-md-3 {
    padding-bottom: 3rem !important;
  }
  .pt-md-3 {
    padding-top: 3rem !important;
  }
  .pl-md-3 {
    padding-left: 3rem !important;
  }
  .pr-md-3 {
    padding-right: 3rem !important;
  }
  .m-md-4 {
    margin: 4rem !important;
  }
  .mb-md-4 {
    margin-bottom: 4rem !important;
  }
  .mt-md-4 {
    margin-top: 4rem !important;
  }
  .ml-md-4 {
    margin-left: 4rem !important;
  }
  .mr-md-4 {
    margin-right: 4rem !important;
  }
  .p-md-4 {
    padding: 4rem !important;
  }
  .pb-md-4 {
    padding-bottom: 4rem !important;
  }
  .pt-md-4 {
    padding-top: 4rem !important;
  }
  .pl-md-4 {
    padding-left: 4rem !important;
  }
  .pr-md-4 {
    padding-right: 4rem !important;
  }
  .m-md-5 {
    margin: 5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 5rem !important;
  }
  .mt-md-5 {
    margin-top: 5rem !important;
  }
  .ml-md-5 {
    margin-left: 5rem !important;
  }
  .mr-md-5 {
    margin-right: 5rem !important;
  }
  .p-md-5 {
    padding: 5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 5rem !important;
  }
  .pt-md-5 {
    padding-top: 5rem !important;
  }
  .pl-md-5 {
    padding-left: 5rem !important;
  }
  .pr-md-5 {
    padding-right: 5rem !important;
  }
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.l-wrapper {
  margin-left: 10.666vw;
  margin-right: 10.666vw;
  max-width: 100rem;
}

@media screen and (min-width: 64em) {
  .l-wrapper {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 64em) {
  .l-wrapper.wrapper01 {
    max-width: 114rem;
  }
}

@media screen and (min-width: 64em) {
  .l-wrapper.wrapper02 {
    max-width: 88rem;
  }
}

.l-sec-about {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 64em) {
  .l-sec-about {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.l-sec-content {
  width: 100%;
}

@media screen and (min-width: 64em) {
  .l-sec-content {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

.l-sec-content p {
  margin-left: 10.666vw;
  margin-right: 10.666vw;
  max-width: 100rem;
}

.l-sec-content img {
  width: 100%;
}

.l-header {
  position: fixed;
  width: 100vw;
  height: 5.2rem;
  background: #fff;
  z-index: 1000;
}

@media screen and (min-width: 64em) {
  .l-header {
    height: 7.7rem;
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 80em) {
  .l-header {
    padding: 0 6rem 0 7.3rem;
  }
}

.header__head {
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 5;
}

@media screen and (min-width: 64em) {
  .header__head {
    justify-content: space-between;
  }
}

.header__logo {
  width: 10rem;
  padding-left: calc(4vw - 1rem);
}

@media screen and (min-width: 64em) {
  .header__logo {
    width: 16rem;
    padding-left: 0;
  }
}

.header__btn {
  width: 4rem;
  height: 4rem;
  position: fixed;
  top: 2.5rem;
  right: calc(4vw - 1rem);
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 64em) {
  .header__btn {
    display: none;
  }
}

.gnav {
  background: #fff;
  position: fixed;
  top: 5.2rem;
  left: 0;
  padding: 0 5.33333vw;
  width: 100vw;
  height: calc(100vh - 5.2rem);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in;
}

.gnav.active {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 64em) {
  .gnav {
    opacity: 1;
    visibility: visible;
    position: static;
    width: auto;
    height: auto;
    background: transparent;
    padding: 0;
  }
}

@media screen and (min-width: 64em) {
  .gnav__list {
    display: flex;
    white-space: nowrap;
  }
}

.gnav__item:not(:last-of-type) {
  border-bottom: 1px solid #7D7A76;
}

@media screen and (min-width: 64em) {
  .gnav__item:not(:last-of-type) {
    border: none;
    margin-right: 4rem;
  }
}

@media screen and (min-width: 80em) {
  .gnav__item:not(:last-of-type) {
    margin-right: 4rem;
  }
}

.gnav__item:hover {
  opacity: 0.5;
}

.gnav__link {
  font-weight: 500;
  color: #000;
  display: block;
  text-align: center;
  padding: 2rem 0;
  font-weight: bold;
}

@media screen and (min-width: 64em) {
  .gnav__link {
    padding: 0;
    text-align: left;
  }
}

.gnav__link-menu {
  color: #fff;
  font-size: 7.2vw;
  font-weight: 700;
}

@media screen and (min-width: 48em) {
  .gnav__link-menu {
    font-size: 3.6rem;
  }
}

.l-main__inner {
  padding-top: 5.2rem;
  background-image: url(../img/top/bg-sp.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: cover;
}

@media screen and (min-width: 64em) {
  .l-main__inner {
    padding-top: 7.7rem;
    background-image: url(../img/top/bg-pc.png);
    background-size: auto;
  }
}

.kv {
  position: relative;
  background-color: #000;
}

.kv__ttl {
  color: #fff;
  position: absolute;
  font-size: 2rem;
  font-weight: bold;
  top: calc( 100vh / 2 - 5rem);
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0000004a;
  padding: 2rem 0;
  z-index: 66;
}

@media screen and (min-width: 48em) {
  .kv__ttl {
    top: calc( 100vh / 2 - 5rem);
    left: 50%;
    font-size: 3rem;
    transform: translate(-50%, -50%);
  }
}

.kv__ttl .kv-flex {
  display: flex;
  width: 105px;
}

@media screen and (min-width: 48em) {
  .kv__ttl .kv-flex {
    width: 122px;
  }
}

.kv__ttl h2 {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.kv__ttl p {
  padding-top: 2rem;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.kv__ttl span {
  font-size: 4rem;
  font-weight: bold;
}

@media screen and (min-width: 48em) {
  .kv__ttl span {
    font-size: 4rem;
  }
}

.kv__ttl::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 105px;
  border-color: transparent transparent #0000004a transparent;
  position: absolute;
  top: -50px;
}

@media screen and (min-width: 48em) {
  .kv__ttl::before {
    border-width: 0 0 60px 122px;
    top: -60px;
  }
}

.kv__ttl::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 105px 0 0;
  border-color: #0000004a transparent transparent transparent;
  position: absolute;
  bottom: -50px;
  left: 0;
}

@media screen and (min-width: 48em) {
  .kv__ttl::after {
    border-width: 60px 122px 0 0;
    bottom: -60px;
  }
}

.kvSlide {
  height: 100vh;
}

@media screen and (min-width: 48em) {
  .kvSlide {
    min-height: 70rem;
  }
}

.kvSlide__item {
  width: 100%;
  height: 100%;
}

.kvSlide figure {
  width: 100%;
  height: 100%;
}

.kvSlide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: right;
}

@media screen and (min-width: 48em) {
  .kvSlide img {
    object-position: center;
  }
}

.logo-bg {
  display: block;
  width: 80px;
  opacity: 0.5;
  margin: 0 auto;
  padding-bottom: 5px;
}

.news-pad {
  padding: 9rem 0;
  background-color: #fff;
}

@media screen and (min-width: 64em) {
  .news-pad {
    padding: 9rem 0 14rem 0;
  }
}

.news {
  width: 100%;
  height: auto;
  background-image: url(../img/top/news-sp.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 6rem 0;
}

.news-ttl {
  padding-bottom: 6rem !important;
}

.news-ttl::before {
  content: "News" !important;
}

.news-box {
  width: 90%;
  max-width: 35rem;
  margin: 0 auto;
  background: #E6E6E6;
  padding: 0 1rem;
}

@media screen and (min-width: 64em) {
  .news-box {
    width: 70%;
    max-width: 60rem;
  }
}

.news-list {
  padding: 0.8rem 0;
  font-size: 1.4rem;
}

@media screen and (min-width: 64em) {
  .news-list {
    display: flex;
  }
}

.news-list:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.news-list dd {
  padding-right: 2.5rem;
  letter-spacing: normal;
  font-weight: bold;
}

@media screen and (min-width: 64em) {
  .news-list dd {
    padding-left: 3rem;
    padding-right: 10rem;
  }
}

.news-list dt {
  padding-left: 3rem;
}

.news-list dt a {
  color: #c9b500;
  font-weight: bold;
  font-size: 1.4rem;
}

.news-list:hover {
  opacity: 0.7;
}

.about {
  width: 100%;
  height: auto;
}

.about-top {
  width: 100%;
  height: 20rem;
  background-color: #fff;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: relative;
}

.about-top span {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 2rem;
  font-weight: bold;
  z-index: 7;
}

@media screen and (min-width: 64em) {
  .about-top span {
    font-size: 3rem;
  }
}

.about-top::before {
  content: '';
  display: block;
  width: 5rem;
  height: 5rem;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  background-image: url(../img/top/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 64em) {
  .about-top::before {
    width: 7rem;
    height: 7rem;
    top: -11%;
  }
}

.about-bottom {
  position: relative;
  height: auto;
  background-color: black;
  background-image: url(../img/top/about-bottom-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 6rem;
}

@media screen and (min-width: 64em) {
  .about-bottom {
    background-size: cover;
    background-position: right;
  }
}

.about-bottom span {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  color: #fff;
  font-size: 2rem;
  display: block;
  font-weight: bold;
}

@media screen and (min-width: 64em) {
  .about-bottom span {
    font-size: 3rem;
  }
}

.about-bottom dd {
  color: #c9b500;
  text-align: center;
  padding-top: 16rem;
}

.about-bottom dt {
  color: #fff;
  text-align: center;
  padding: 6rem 0;
  text-align: left;
  line-height: 3;
  width: 90%;
  margin: 0 auto;
}

@media screen and (min-width: 64em) {
  .about-bottom dt {
    text-align: center;
    line-height: 3.5;
  }
}

.about-bottom p {
  text-align: center;
  color: #fff;
}

.about__btn {
  height: auto;
  width: 22rem;
  margin: 0 auto;
  background-color: #c9b500;
  line-height: 3rem;
  font-weight: bold;
  transition: all 0.2s !important;
}

.about__btn__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.about__btn__box img {
  width: auto;
  height: 0.8rem;
}

.about__btn a p {
  color: #fff;
  width: 100%;
  display: block;
  font-weight: bold;
}

.about__btn:hover {
  background-color: rgba(201, 181, 0, 0.7);
}

.frontaccess {
  height: auto;
  padding: 15rem 0;
}

.frontaccess__ttl::before {
  content: "Access" !important;
}

.frontaccess__txt {
  padding-top: 6rem;
  line-height: 2.2;
}

@media screen and (min-width: 64em) {
  .frontaccess__txt {
    padding-top: 10rem;
    font-size: 1.8rem;
  }
}

.frontaccess__txt span {
  font-weight: bold;
  cursor: pointer;
}

.gallery__ttl::before {
  content: "Gallery" !important;
}

.img-wrap {
  width: 100%;
  margin: 0 auto;
  height: auto;
  margin-bottom: 18rem;
}

@media screen and (min-width: 64em) {
  .img-wrap {
    margin-bottom: 22rem;
  }
}

.img-wrap .img {
  width: 100%;
  height: auto;
  background: #E6E6E6;
  margin-bottom: 1rem;
}

@media screen and (min-width: 64em) {
  .img-wrap .img {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 0;
    align-items: stretch;
    height: 600px;
  }
}

.img-wrap .large {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1rem;
}

@media screen and (min-width: 64em) {
  .img-wrap .large {
    width: calc(( 100% - 3rem) / 2);
    margin-bottom: 0;
    height: 100%;
  }
}

.img-wrap .img-side {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 64em) {
  .img-wrap .img-side {
    width: calc(( 100% - 3rem) / 2);
  }
}

.img-wrap .mid {
  width: 100%;
  height: calc(( 100% - 1rem) / 2);
  margin-bottom: 1rem;
}

@media screen and (min-width: 64em) {
  .img-wrap .mid {
    height: calc(( 100% - 3rem) / 2);
    margin-bottom: 3rem;
  }
}

.img-wrap .img-small {
  width: 100%;
  height: calc(( 100% - 1rem) / 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (min-width: 64em) {
  .img-wrap .img-small {
    height: calc(( 100% - 3rem) / 2);
  }
}

.img-wrap .small {
  width: calc(( 100% - 1rem) / 2);
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 64em) {
  .img-wrap .small {
    width: calc(( 100% - 3rem) / 2);
  }
}

.about__ttl {
  padding-bottom: 0 !important;
  width: 2.8rem !important;
}

.about__ttl::before {
  content: "About Ryushin Yamada" !important;
  left: 2.5rem !important;
}

.abouttxt__wrapper {
  padding: 2rem 0;
  background-color: #fff;
}

@media screen and (min-width: 64em) {
  .abouttxt__wrapper {
    position: relative;
    padding: 4rem 0;
  }
}

@media screen and (min-width: 64em) {
  .abouttxt__ttl {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    position: absolute;
    right: 10%;
  }
}

.abouttxt__ttl p {
  font-size: 1.8rem;
  font-weight: bold;
}

.abouttxt__ttl01 {
  font-size: 1.2rem !important;
}

@media screen and (min-width: 64em) {
  .abouttxt__ttl01 {
    padding-left: 1rem;
  }
}

.abouttxt__ttl02 {
  padding-top: 1rem;
}

.abouttxt__ttl span {
  font-size: 4rem;
  color: #c9b500;
  line-height: 1;
  font-weight: bold;
  padding-left: 2rem;
  padding-top: 1rem;
}

@media screen and (min-width: 64em) {
  .abouttxt__ttl span {
    font-size: 6rem;
  }
}

.abouttxt p {
  padding-top: 2.5rem;
  line-height: 2.4;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (min-width: 64em) {
  .abouttxt p {
    font-size: 1.6rem;
    padding-top: 0;
    line-height: 4;
  }
}

.history__wrapper {
  background-image: url(../img/top/bg-sp.png);
  padding: 2rem 0;
}

@media screen and (min-width: 64em) {
  .history__wrapper {
    padding: 2rem 0 4rem 0;
  }
}

.history__wrapper ul {
  padding-top: 2rem;
  line-height: 2.4;
}

@media screen and (min-width: 64em) {
  .history__wrapper ul {
    padding-left: 5rem;
    line-height: 3;
  }
}

.aboutpage-ttl {
  font-size: 3rem;
  font-weight: bold;
  border-bottom: 1px solid #271353;
}

.book__wrapper {
  padding: 2rem 0 10rem 0;
  background-color: #fff;
}

@media screen and (min-width: 64em) {
  .book__wrapper {
    padding: 2rem 0 16rem 0;
  }
}

.book__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 64em) {
  .book__list {
    justify-content: space-around;
  }
}

.book__item {
  width: calc(( 100% - 4rem) / 2);
  margin-top: 2rem;
}

@media screen and (min-width: 64em) {
  .book__item {
    width: calc(( 100% - 5.6rem) / 3);
    padding: 0 4rem;
    margin-top: 4rem;
  }
}

.book__item:hover {
  opacity: 0.7;
}

.access__box {
  padding-top: 3rem;
}

@media screen and (min-width: 64em) {
  .access__box {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
}

.access__box dl {
  padding: 1rem 2rem 2rem 2rem;
  border: 1px solid #C69C6D;
  background-color: #fff;
}

@media screen and (min-width: 64em) {
  .access__box dl {
    width: 48%;
  }
}

.access__box dl:first-child {
  margin-bottom: 1rem;
}

@media screen and (min-width: 64em) {
  .access__box dl:first-child {
    margin-bottom: 0;
  }
}

.access__box dd {
  font-size: 2rem;
  color: #C69C6D;
  font-weight: bold;
}

.access__box dd::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #C69C6D;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.access__box dt {
  font-size: 1.2rem;
  letter-spacing: normal;
  font-weight: bold;
  padding-left: 2rem;
  padding-top: 1rem;
}

.access__txt {
  padding-top: 1rem;
}

@media screen and (min-width: 64em) {
  .access__txt {
    padding-top: 3rem;
  }
}

.access__txt p {
  text-align: center;
  padding-bottom: 0 !important;
}

.access__txt span {
  font-weight: bold;
}

.single-txt {
  width: 80%;
  max-width: 500px;
}

.single-txt p {
  text-align: left !important;
}

footer {
  background-color: #000;
  padding: 10rem 0;
}

footer img {
  width: 18rem;
  margin: 0 auto;
  display: block;
  padding-bottom: 5rem;
}

@media screen and (min-width: 64em) {
  footer img {
    width: 20rem;
  }
}

footer a {
  color: #fff;
}

.footer-access {
  text-align: center;
  color: #fff;
  padding: 1rem 0;
  cursor: pointer;
  line-height: 2.2;
}

@media screen and (min-width: 64em) {
  .footer-access {
    padding: 0 0 0 3rem;
    font-size: 1.4rem;
  }
}

.ttl {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  margin: 0 auto;
  padding-bottom: 6rem;
  position: relative;
  font-weight: bold;
  font-size: 1.6rem;
}

@media screen and (min-width: 64em) {
  .ttl {
    font-size: 2.5rem;
    padding-bottom: 15rem;
  }
}

.ttl::before {
  content: "";
  display: block;
  font-size: 1rem;
  color: #271353;
  position: absolute;
  top: -0.7rem;
  left: 2rem;
  letter-spacing: normal;
}

@media screen and (min-width: 64em) {
  .ttl::before {
    top: -1rem;
    left: 3.5rem;
  }
}

.intro__item__about {
  width: 100%;
  height: 25rem;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 64em) {
  .intro__item__about {
    height: 30rem;
  }
}

.intro__item__about::before {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.intro__item__about:hover span {
  background: rgba(0, 0, 0, 0.7);
  transition: all 1.2s ease;
}

.intro__item__about:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 1.2s ease;
}

.intro__item__about img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20%;
  opacity: 0.5;
  transition: all 1s ease-out;
}

.intro__item__about img:hover {
  transform: scale(1.03, 1.03);
  opacity: 1;
}

.intro__item__txt {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(70%, -50%);
  color: #fff;
}

@media screen and (min-width: 64em) {
  .intro__item__txt {
    transform: translate(56%, -50%);
  }
}

.intro__item__txt span {
  font-size: 1.2rem;
  padding: 2rem 0.5rem;
}

@media screen and (min-width: 64em) {
  .intro__item__txt span {
    font-size: 2rem;
  }
}

.intro__item {
  width: 50%;
  height: 25rem;
  background: #000;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 64em) {
  .intro__item {
    height: 30rem;
  }
}

.intro__item figure {
  width: 100%;
  height: 100%;
}

.intro__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 30%;
  transition: all 1s ease-out;
  opacity: 0.5;
}

@media screen and (min-width: 64em) {
  .intro__item img {
    object-position: top;
  }
}

.intro__item img:hover {
  transform: scale(1.05, 1.05);
  opacity: 1;
}

.intro__item span {
  color: #fff;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  width: auto;
}

@media screen and (min-width: 64em) {
  .intro__item span {
    font-size: 2rem;
  }
}

.intro__item::before {
  content: '';
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.intro__item:hover span {
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem 0;
}

.intro__item:hover::before {
  background-color: rgba(0, 0, 0, 0.3);
}

.tel-link {
  color: #000;
}

.tel-link-white {
  color: #fff;
}

iframe {
  display: block;
  width: 100%;
  height: 30rem;
  margin: 0 auto;
}

@media screen and (min-width: 64em) {
  iframe {
    height: 50rem;
  }
}

.pagettl {
  top: 50% !important;
  transform: translate(0, -50%) !important;
  width: 5rem;
  margin: 0 auto;
}

.pagettl__wrapper {
  background-color: #fff;
  width: 100%;
  height: 45rem;
}

@media screen and (min-width: 64em) {
  .pagettl__wrapper {
    height: 45rem;
    position: relative;
  }
}

@media screen and (min-width: 64em) {
  .pagettl {
    width: auto;
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    white-space: nowrap;
  }
}

.gomaku {
  background: url(../img/gomaku/gomaku-bg-sp.jpg) no-repeat;
  background-size: cover;
}

@media screen and (min-width: 64em) {
  .gomaku {
    background: url(../img/gomaku/gomaku-bg.jpg) no-repeat;
    background-position: center center;
  }
}

.mizuko {
  background: url(../img/mizuko/mizuko-bg-sp.jpg) no-repeat;
  background-size: cover;
}

@media screen and (min-width: 64em) {
  .mizuko {
    background: url(../img/mizuko/mizuko-bg.jpg) no-repeat;
    background-position: center center;
  }
}

.ohaka {
  background: url(../img/ohaka/ohaka-bg-sp.jpg) no-repeat;
  background-size: cover;
  background-position: left bottom;
}

@media screen and (min-width: 64em) {
  .ohaka {
    background: url(../img/ohaka/ohaka-bg.jpg) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }
}

.kourei {
  background: url(../img/kourei/kourei-bg-sp.jpg) no-repeat;
  background-size: cover;
  background-position: right bottom;
}

@media screen and (min-width: 64em) {
  .kourei {
    background: url(../img/kourei/kourei-bg.jpg) no-repeat;
    background-position: center center;
  }
}

.access {
  background: url(../img/access/access-bg-sp.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 64em) {
  .access {
    background: url(../img/access/access-bg.jpg) no-repeat;
    background-size: 100% auto;
    background-position: center center;
  }
}

.singlebg {
  background: url(../img/single/single-bg-sp.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 64em) {
  .singlebg {
    background: url(../img/single/single-bg.jpg);
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.ttl-bg {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3rem 1rem;
  color: #fff;
  align-items: center;
}

.content-ttl {
  font-size: 1.8rem;
  text-align: center;
  margin: 10rem 0;
}

@media screen and (min-width: 64em) {
  .content-ttl {
    font-size: 2rem;
    margin: 15rem 0;
  }
}

.content-ttl span {
  font-weight: bold;
  display: inline-block;
  border-top: 1px solid #271353;
  border-bottom: 1px solid #271353;
}

.content-txt {
  padding-bottom: 6rem;
}

@media screen and (min-width: 64em) {
  .content-txt {
    padding-bottom: 12rem;
  }
}

.content-txt figure {
  padding-bottom: 6rem;
}

@media screen and (min-width: 64em) {
  .content-txt figure {
    padding-bottom: 8rem;
  }
}

.content-txt p {
  padding-bottom: 1rem;
  font-weight: bold;
  line-height: 2.4;
}

@media screen and (min-width: 64em) {
  .content-txt p {
    padding-bottom: 3rem;
    text-align: center;
    line-height: 3;
  }
}

.figure-pd {
  padding-top: 5rem;
}

.navBar {
  width: 3rem;
  height: 1.8rem;
  cursor: pointer;
  position: relative;
}

.navBar__line {
  display: block;
  width: 100%;
  height: 0.1rem;
  background: #000;
  position: absolute;
}

.navBar__line.line01.active {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.navBar__line.line02 {
  top: 50%;
  transform: translateY(-50%);
}

.navBar__line.line02.active {
  display: none;
}

.navBar__line.line03 {
  bottom: 0;
}

.navBar__line.line03.active {
  top: 50%;
  transform: translateY(-50%) rotate(-315deg);
}

.border-white {
  border-color: #fff !important;
}

.pageTop {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  display: none;
}

.pageTop.active {
  display: block;
}

.contact {
  color: #000;
}

input[type="checkbox"] {
  opacity: 0;
}

label.check {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 22px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

label.check::before,
label.check::after {
  position: absolute;
  content: '';
  -webkit-transition: all .2s;
  transition: all .2s;
}

label.check::before {
  top: 50%;
  left: 0;
  width: 21px;
  height: 21px;
  margin-top: -12px;
  background: #fff;
  border: 1px solid #5D5D5D;
}

label.check::after {
  opacity: 0;
  top: 50%;
  left: 4px;
  width: 12px;
  height: 6px;
  margin-top: -6px;
  border-left: 2px solid #5D5D5D;
  border-bottom: 2px solid #5D5D5D;
  -webkit-transform: rotate(-45deg) scale(0.5);
  transform: rotate(-45deg) scale(0.5);
}

label.check:hover::before {
  background: #fff;
}

input[type="checkbox"]:checked + label.check::before {
  background: #fff;
  border: 1px solid #5D5D5D;
}

input[type="checkbox"]:checked + label.check::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.form__item {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 64em) {
  .form__item {
    border-top: 0.05rem solid #1A1311;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.form__label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 64em) {
  .form__label {
    flex: 0 0 21rem;
    margin-right: 3.5rem;
  }
}

.form__label span {
  font-size: 1rem;
  background: #000;
  color: #fff;
  padding: 0.5rem 1.5rem;
}

.privacyCheck {
  font-size: 1.5rem;
  margin: 2rem 0 3rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

@media screen and (min-width: 64em) {
  .privacyCheck {
    flex-direction: row;
    align-items: flex-start;
    margin: 4.5rem 0 6.5rem;
  }
}

.privacyCheck a {
  color: #454545;
  text-decoration: underline;
}

.privacyCheck__txt {
  margin-bottom: 1rem;
}

@media screen and (min-width: 64em) {
  .privacyCheck__txt {
    margin-bottom: 0;
    margin-left: 3rem;
  }
}

.sentArea {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}

@media screen and (min-width: 64em) {
  .sentArea {
    margin-bottom: 10rem;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
textarea {
  font-size: 1.6rem;
}

@media screen and (min-width: 64em) {
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  textarea {
    font-size: 1.3rem;
  }
}

.submitBox {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.submitBox input {
  width: 12rem;
  height: 4rem;
  margin: 0 1rem;
}

label.check {
  transform: translateY(-240%);
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .ie-only {
    display: none;
  }
}

.fadeUp {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 1s ease-in;
}

.fadeUp.active {
  opacity: 1;
  transform: translateY(0);
}

.fadeUp50 {
  opacity: 0;
  transform: translateY(2rem);
  transition: all 1s ease-in;
}

.fadeUp50.active {
  opacity: 1;
  transform: translateY(-50%);
}

.fadeMask {
  position: relative;
}

.fadeMask img {
  opacity: 0;
  transform: translateY(0.4rem);
  transition: all .4s ease-in .8s;
}

.fadeMask::before {
  content: '';
  display: inline-block;
  background: #2B2A2A;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in;
}

.fadeMask.active img {
  opacity: 1;
  transform: translateY(0);
}

.fadeMask.active::before {
  width: 0%;
}

.fadeIn {
  opacity: 0;
  transition: all 1s ease-in;
}

.fadeIn.active {
  opacity: 1;
}

.loading {
  width: 100vw;
  height: 100vh;
  transition: all 1.5s;
  background-color: #2B2A2A;
  /* 以下のコードを追加 */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loading.active {
  opacity: 0;
  visibility: hidden;
}
