// ====================================
// レイアウトの調整に関わる設定
// ====================================

.l-wrapper {
  margin-left: 10.666vw;
  margin-right: 10.666vw;
  max-width: 100rem;

  @include mq(md){
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  &.wrapper01 {
    @include mq(md){
      max-width: 114rem;
    }
  }
  &.wrapper02 {
    @include mq(md){
      max-width: 88rem;
    }
  }

}


.l-sec{


  &-about{
    display: flex;
    flex-wrap: wrap;

    @include mq(md){
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &-content{
    width: 100%;
    @include mq(md){
      width: 80%;
      margin-right: auto;
      margin-left: auto;
    }
    & p{
      margin-left: 10.666vw;
      margin-right: 10.666vw;
      max-width: 100rem;
    }

    & img{
      width: 100%;
    }
  }

}

