// ====================================
// 共有スタイルシート
// ====================================

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.75;
  letter-spacing: 0.2em;
  font-size: 1.3rem;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'palt';
  font-family: $font-primary;
  color: $color-base;
  
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

p{
  text-align: justify;
}

.fwb{
  font-weight: bold;
}

.tac{
  text-align: center;
}

// ====================================
// 以下、固有のBaseスタイルシート
// ====================================
body{
  font-size: 1.2rem;
  @include mq(md){
    font-size: 1.3rem;
  }
}

a{
  transition: all .4s ease-in-out;
  text-decoration: none;
  &:hover{
    opacity: 0.9;
  }
}
