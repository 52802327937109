// ====================================
// フッターのレイアウトに関わる設定
// ====================================

.l-footer {


}


.footer{

}


// ====================================
// footer部分
// ====================================
footer{
    background-color: #000;
    padding: 10rem 0;
    
    & img{
        width: 18rem;
        margin: 0 auto;
        display: block;
        padding-bottom: 5rem;
        @include mq(md){
            width: 20rem;
        }
    }
    & a{
        color: #fff;
    }

   
}
.footer{
    &-access{
        text-align: center;
        color: #fff;
        padding: 1rem 0;
        cursor: pointer;
        line-height: 2.2;
        @include mq(md){
            padding: 0 0 0 3rem;
            font-size: 1.4rem;
        }
    }
    
}