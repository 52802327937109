// ====================================
// 複数個所で使用しているパーツ設定
// ====================================

.ttl{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    margin: 0 auto;
    padding-bottom: 6rem;
    position: relative;
    font-weight: bold;
    font-size: 1.6rem;
    @include mq(md){
        font-size: 2.5rem;
        padding-bottom: 15rem;
    }
    &::before{
        content: "";
        display: block;
        font-size: 1rem;
        color: $cPurple;
        position: absolute;
        top: -0.7rem;
        left: 2rem;
        letter-spacing: normal;
        @include mq(md){
            top: -1rem;
            left: 3.5rem;
        }
    }

}

// ====================================
// intro部分
// ====================================
.intro__item{
    &__about{
    width: 100%;
    height: 25rem;
    position: relative;
    overflow: hidden;
    @include mq(md){
        height: 30rem;
    }
        &::before{
            content: '';
            display: block;
            background-color: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            position: absolute;
            pointer-events: none;
        }
        &:hover{
            & span{
                background: rgba(0, 0, 0, 0.7);
                transition: all 1.2s ease;
            }
            &::before{
                background-color: rgba(0, 0, 0, 0.3);
                transition: all 1.2s ease;
            }
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 20%;
            opacity: 0.5;
            transition: all 1s ease-out;
            &:hover{
                transform:scale(1.03,1.03);
               opacity: 1;
            }
        }
    }
    &__txt{
        position: absolute;
        top: 50%;
        right: 25%;
        transform:translate(70%,-50%);
        color: #fff;
        @include mq(md){
            transform:translate(56%,-50%);
        }
        & span{
            font-size: 1.2rem;
            padding: 2rem 0.5rem;
            @include mq(md){
                font-size: 2rem;
            }
        }
    }
}

.intro{
    &__item{
        width: 50%;
        height: 25rem;
        background: #000;
        position: relative;
        overflow: hidden;
        @include mq(md){
            height: 30rem;
        }
        & figure{
            width: 100%;
            height: 100%;
        }
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 30%;
            transition: all 1s ease-out;
            opacity: 0.5;
            @include mq(md){
                object-position: top;
            }
            &:hover{
                transform:scale(1.05,1.05);
                opacity: 1;
            }
        }
        & span{
            color: #fff;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            font-weight: bold;
            width: auto;
            @include mq(md){
                font-size: 2rem;
            }
        }
        &::before{
            content: '';
            display: block;
            background-color: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            position: absolute;
            pointer-events: none;
        }
        &:hover{
            & span{
                background: rgba(0, 0, 0, 0.7);
                padding: 1rem 0;
            }
            &::before{
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

}

// 電話リンク
.tel-link{
    color: #000;
}

.tel-link-white{
    color: #fff;
}

// 地図
iframe{
    display: block;
    width: 100%;
    // max-width: 35rem;
    height: 30rem;
    margin: 0 auto;
    @include mq(md){
        height: 50rem;
    }
}
// 下層ページ　タイトル部分
.pagettl{
    &__wrapper{
      background-color:#fff;
      width: 100%;
      height: 45rem;
      @include mq(md){
        height: 45rem;
        position: relative;
      }
    }
    top: 50% !important;
        transform: translate(0,-50%) !important;
        width: 5rem;
        margin: 0 auto;

    @include mq(md){
        width: auto;
        position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%,-50%) !important;
    white-space: nowrap;
      }
}
// 護摩供ページ
.gomaku{
    background: url(../img/gomaku/gomaku-bg-sp.jpg) no-repeat;
    background-size: cover;
    @include mq(md){
        background: url(../img/gomaku/gomaku-bg.jpg) no-repeat;
        // background-size: auto 100%;
        background-position:center center;
    }
}
// 水子供養ページ
.mizuko{
    background: url(../img/mizuko/mizuko-bg-sp.jpg) no-repeat;
    background-size: cover;
    @include mq(md){
        background: url(../img/mizuko/mizuko-bg.jpg) no-repeat;
        // background-size: auto 100%;
        background-position:center center;
    }
}
// お墓ページ
.ohaka{
    background: url(../img/ohaka/ohaka-bg-sp.jpg) no-repeat;
    background-size: cover;
    background-position:left bottom;
    @include mq(md){
        background: url(../img/ohaka/ohaka-bg.jpg) no-repeat;
        background-size: 100% 100%;
        background-position:center center;
    }
}
// 降霊術ページ
.kourei{
    background: url(../img/kourei/kourei-bg-sp.jpg) no-repeat;
    background-size: cover;
    background-position:right bottom;
    @include mq(md){
        background: url(../img/kourei/kourei-bg.jpg) no-repeat;
        // background-size: auto 100%;
        background-position:center center;
    }
}
// 交通案内ページ
.access{
    background: url(../img/access/access-bg-sp.jpg) no-repeat;
    background-size: cover;
    background-position:center;
    @include mq(md){
        background: url(../img/access/access-bg.jpg) no-repeat;
        background-size: 100% auto;
        background-position:center center;
    }
}

// お知らせページ
.singlebg{
    background: url(../img/single/single-bg-sp.jpg);
    background-size: cover;
    background-position:center;
    background-repeat: no-repeat;
    @include mq(md){
        background: url(../img/single/single-bg.jpg);
        background-size: 100% auto;
        background-position:center center;
        background-repeat: no-repeat;
    }
}

// タイトルバッグ影
.ttl-bg{
    background-color: rgba($color: #000000, $alpha: 0.3);
    padding: 3rem 1rem;
    color: #fff;
    // height: 10rem;
    // height: auto;
    // min-height: 10rem;
    align-items: center;
}

// 各ページタイトル
.content-ttl{
    font-size: 1.8rem;
    text-align: center;
    margin: 10rem 0;
    @include mq(md){
        font-size: 2rem;
        margin: 15rem 0;
      }

    & span{
        font-weight: bold;
        display: inline-block;
        border-top: 1px solid $cPurple;
        border-bottom: 1px solid $cPurple;
    }
}



// 各ページ内容
.content-txt{
    padding-bottom: 6rem;
    @include mq(md){
        padding-bottom: 12rem;
    }
    & figure{
        padding-bottom: 6rem;
        @include mq(md){
        padding-bottom: 8rem;
        }
    }
    & p{
        padding-bottom: 1rem;
        font-weight: bold;
        line-height: ( 60 / 25);
        @include mq(md){
            padding-bottom: 3rem;
            text-align: center;
            line-height: 3;
        }
    }
}

.figure-pd{
    padding-top: 5rem;
}

