// ====================================
// IE11 にのみ適用される
// ====================================


@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,

  .ie-only {
    display: none;
  }

  @include mq(md){

  }



}



// ====================================
// Edge にのみ適用される
// ====================================



@supports (-ms-ime-align: auto) {

  /* Edge にのみ適用される */

}
