// ====================================
// Formに関わる設定
// ====================================

// Form
.contact{
  color: #000;

}

// チェックボックス リセット ＆ カスタマイズ

input[type="checkbox"] {
  opacity: 0;
}

label.check {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 22px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

label.check::before,
label.check::after {
  position: absolute;
  content: '';
  -webkit-transition: all .2s;
  transition: all .2s;
}

label.check::before {
  top: 50%;
  left: 0;
  width: 21px;
  height: 21px;
  margin-top: -12px;
  background: #fff;
  border: 1px solid #5D5D5D;
}

label.check::after {
  opacity: 0;
  top: 50%;
  left: 4px;
  width: 12px;
  height: 6px;
  margin-top: -6px;
  border-left: 2px solid #5D5D5D;
  border-bottom: 2px solid #5D5D5D;
  -webkit-transform: rotate(-45deg) scale(.5);
  transform: rotate(-45deg) scale(.5);
}

label.check:hover::before {
  background: #fff;
}

input[type="checkbox"]:checked+label.check::before {
  background: #fff;
  border: 1px solid #5D5D5D;
}

input[type="checkbox"]:checked+label.check::after {
  opacity: 1;
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}



.form{
  &__item{
    margin-bottom: 1.5rem;

    @include mq(md){
      border-top: 0.05rem solid #1A1311;
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  &__label{
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq(md){
      flex: 0 0 21rem;
      margin-right: 3.5rem;
    }

    span{
      font-size: 1rem;
      background: #000;
      color: #fff;
      padding: 0.5rem 1.5rem;
    }
  }
}

.privacyCheck{
  font-size: 1.5rem;
  margin: 2rem 0 3rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include mq(md){
    flex-direction: row;
    align-items: flex-start;
    margin: 4.5rem 0 6.5rem;
  }

  a{
    color: #454545;
    text-decoration: underline;
  }

  &__txt{
    margin-bottom: 1rem;
    @include mq(md){
      margin-bottom: 0;
      margin-left: 3rem;
    }
  }
}

.sentArea{
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @include mq(md){
    margin-bottom: 10rem;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
textarea{
  font-size: 1.6rem;
  @include mq(md) {
    font-size: 1.3rem;
  }
}

// 送信確認
.submitBox{
  margin-top: 2rem;
  display: flex;
  justify-content: center;

  input{
    width: 12rem;
    height: 4rem;
    margin: 0 1rem;
  }
}



label.check {
  transform: translateY(-240%);
}
