// ====================================
// リセット カスタム　CSS
// ====================================

// 電話番号リンクホバー時のカーソルイベント非表示

a[href^="tel:"] {
  cursor: default;

  @include mq(tb) {
    pointer-events: none;
  }
}
